const header = document.querySelector("header.text-bronzeOlive");
const container = document.querySelector("#main-navigation");
const nav = document.querySelector("#main-navigation > ul");

const navToggle = document.querySelector("#hamburger");
const menuIcon = document.querySelector(".menuIcon");
const menuText = document.querySelector("#hamburger span");

const navImage = document.querySelectorAll(".menuImage");

const subnav = document.querySelectorAll(".subnav");
const subnavToggle = document.querySelectorAll(".toggleSubnav");
const subnavClose = document.querySelectorAll(".closeSubnav");

// Toggle nav
navToggle.addEventListener("click", () => {
  nav.classList.contains("-translate-x-full") ? openNav() : closeNav();
});

// Toggle subnav
subnavToggle.forEach((elem, i) => {
  const subnav = elem.nextElementSibling;

  elem.addEventListener("click", () => {
    subnav.classList.remove("hidden");
    navImage[i + 1].classList.remove("opacity-0");
  });
});

// Close subnav
subnavClose.forEach((elem, i) => {
  elem.addEventListener("click", () => {
    closeSubnav();
    navImage[0].classList.remove("opacity-0");
  });
});

// Functions
const openNav = () => {
  if (header) {
    header.classList.add("text-white");
  }
  container.classList.remove("pointer-events-none");
  nav.classList.remove("-translate-x-full");
  navToggle.classList.add("!text-black");
  navImage[0].classList.remove("opacity-0");
  menuIcon.classList.add("active");
  menuText.innerHTML = "Close";
};

const closeNav = () => {
  if (header) {
    header.classList.remove("text-white");
  }
  container.classList.add("pointer-events-none");
  nav.classList.add("-translate-x-full");
  navToggle.classList.remove("!text-black");
  navImage[0].classList.add("opacity-0");
  menuIcon.classList.remove("active");
  menuText.innerHTML = "Menu";
  closeSubnav();
};

const closeSubnav = () => {
  Array.from(subnav).forEach((el) => el.classList.add("hidden"));
  Array.from(navImage).forEach((el) => el.classList.add("opacity-0"));
};

export { openNav, closeNav, closeSubnav };

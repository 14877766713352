import Swiper from "swiper/swiper-bundle.mjs";

const carousel = new Swiper(".carousel", {
  centeredSlides: true,
  loop: true,
  slidesPerView: 1.25,
  spaceBetween: 16,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
  },
});

export { carousel };

import * as nav from "./modules/navigation.js";
import * as carousel from "./modules/carousels.js";
import animateOnScroll from "./modules/observer.js";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
    headerFormat();
  },
  false,
);

// Page transition
window.addEventListener("beforeunload", function () {
  $("main, footer").addClass("opacity-0 duration-500 transition");
});

// Accordions
$(".accordion-toggle").click(function () {
  $(this).toggleClass("text-invisibleGreen");
  $(this).find("svg").toggleClass("rotate-90");
  $(this).next().slideToggle();
});

const headerFormat = () => {
  var target = document.querySelector(".mainImage");
  var header = document.querySelector("header");

  if (target) {
    if (window.scrollY > target.offsetTop + target.offsetHeight) {
      header.classList.add("bg-bronzeOlive");
    } else {
      header.classList.remove("bg-bronzeOlive");
    }
  }
};

window.addEventListener("scroll", headerFormat, false);
